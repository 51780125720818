<template>
  <div>
    <table-filter
      v-if="modalIsActive"
      :range="true"
      @close="toggleFilter"
      @save="setFilter"
    />
    <div class="columns is-multiline is-vcentered is-mobile">
      <div class="column">
        <span v-if="rows">
          {{ title }}
        </span>
      </div>
      <div class="column is-narrow">
        <div class="field is-grouped">
          <div class="control">
            <table-search
              @change="setSearch"
              placeholder="Search by branch name"
            />
          </div>
          <div class="control">
            <date-picker-button
              @toggleFilter="toggleFilter"
              @clearFilter="clearFilter"
              :to="query.to"
              :from="query.from"
            />
          </div>
        </div>
      </div>
      <div class="column is-12">
        <SummaryBoxes
          v-if="usage"
          v-bind="{
            usage,
            isCompanionUser,
            hasAutoCheck,
            hasAutoCheckValuation
          }"
        />
      </div>
    </div>
    <table
      class="table is-fullwidth is-size-7-mobile is-striped is-transparent"
    >
      <table-header
        v-bind="{ columns, sort }"
        @change="setSort"
        v-if="!isEmpty"
      />
      <tbody v-if="!rows">
        <tr>
          <td v-for="(column, index) in columns" :key="index">
            <content-placeholder />
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="rows.length > 0">
        <tr v-for="(row, index) in rows" :key="index">
          <td>{{ row.name }}</td>
          <td>
            <span>{{ row.users || '-' }}</span>
          </td>
          <td v-if="isCompanionUser">
            <span>{{ row.companion || '-' }}</span>
          </td>
          <td v-if="hasAutoCheck">
            <span>{{ row.autocheck || '-' }}</span>
          </td>
          <td v-if="hasAutoCheckValuation">
            <span>{{ row.autocheckValuation || '-' }}</span>
          </td>
          <td>
            <span>{{ row.vpp || '-' }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <table-empty v-if="isEmpty" />
    <pagination v-bind="{ page, pages }" :fn="'fetch'" @change="setPage" />
  </div>
</template>

<script>
import * as account from 'modules/account/services'
import { mapGetters } from 'vuex'
import pagination from 'core/modules/pagination/mixins/pagination.mixin'
import tableHeader from 'core/modules/table-header/mixins/tableHeader.mixin'
import tableFilter from 'core/modules/table-filter/mixins/tableFilter.mixin'
import tableSearch from 'core/modules/table-search/mixins/tableSearch.mixin'
import activityTable from 'core/mixins/activityTable'
export default {
  name: 'AccountBranchUsageTable',
  components: {
    tableHeader: () =>
      import('core/modules/table-header/components/TableHeader'),
    contentPlaceholder: () => import('core/components/ContentPlaceholder'),
    tableFilter: () =>
      import('core/modules/table-filter/components/TableFilter'),
    tableEmpty: () => import('core/components/TableEmpty'),
    SummaryBoxes: () => import('../history/AccountHistorySummaryBoxes'),
    datePickerButton: () =>
      import('core/modules/table-filter/components/DatePickerButton')
  },
  data: () => ({
    response: null,
    usage: null
  }),
  async created() {
    this.fetch()
  },
  mixins: [
    activityTable,
    tableHeader({ fetch: 'fetch' }),
    tableFilter({ fetch: 'fetch' }),
    tableSearch({ fetch: 'fetch' }),
    pagination({
      fn: {
        data: 'response',
        fetch: 'fetch'
      }
    })
  ],
  computed: {
    ...mapGetters('auth', [
      'organisation',
      'isCompanionUser',
      'hasAutoCheck',
      'hasAutoCheckValuation'
    ]),
    total() {
      return this.response?.total
    },
    title() {
      return `Showing ${this.rows?.length} of ${this.total}`
    },
    orgId() {
      return this.organisation.id
    },
    columns() {
      let columns = [
        { label: 'Branch name', type: 'organisations.name', query: true },
        { label: 'Users', type: 'users', query: true },
        { label: 'Companion', type: 'companion', query: true },
        { label: 'AutoCheck', type: 'autocheck', query: true },
        { label: 'Valuation', type: 'autocheckValuation', query: true },
        { label: 'VPP', type: 'vpp', query: true }
      ]
      if (!this.hasAutoCheck)
        columns = columns.filter(col => col.type !== 'autocheck')
      if (!this.hasAutoCheckValuation)
        columns = columns.filter(col => col.type !== 'autocheckValuation')
      if (!this.isCompanionUser)
        columns = columns.filter(col => col.type !== 'companion')
      return columns
    },
    rows() {
      return this.response?.results
    }
  },
  methods: {
    async fetch(req) {
      const { query, getSummary } = this
      getSummary()
      try {
        this.$wait.start('response')
        this.response = await account.getOrgTable({ query, req })
      } catch (err) {
        if (err?.response?.status !== 401) {
          this.$notify('History unavailable')
          throw err
        }
      } finally {
        this.$wait.end('response')
      }
    },
    async getSummary() {
      const { query } = this
      try {
        this.$wait.start('usage')
        this.usage = await account.getOrgSummary({ query })
      } catch (err) {
        if (err?.response?.status !== 401) {
          this.$notify('Usage unavailable')
          throw err
        }
      } finally {
        this.$wait.end('usage')
      }
    }
  }
}
</script>
